<template>
  <p class="text-500">
    <BaseIcon :icon="icon" class="mr-2" :class="classes" />
    {{ label }}
  </p>
</template>

<script>
import BaseIcon from "@/modules/base-components-bc/Base/BaseIcon";

export default {
  name: "ChapterItemsCompletedLabel",
  components: { BaseIcon },
  props: {
    chapter: {
      type: Object,
      required: true,
      default: () => ({
        courseChapterItemsCount: null,
        completedCourseChapterItemsCount: null,
      }),
    },
  },
  computed: {
    label() {
      return this.$t("uc.my.chapters.show.completedChapterItems", {
        completed: this.chapter.completedCourseChapterItemsCount,
        count: this.chapter.courseChapterItemsCount,
      });
    },
    icon() {
      if (this.chapter.completedCourseChapterItemsCount === this.chapter.courseChapterItemsCount) {
        return "pi pi-check";
      }

      return "pi pi-pencil";
    },
    classes() {
      if (this.chapter.completedCourseChapterItemsCount === this.chapter.courseChapterItemsCount) {
        return "text-success";
      }

      return "text-500";
    },
  },
};
</script>
