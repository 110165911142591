<template>
  <BaseContainer fluid class="max-w-1024">
    <BaseCard class="p-3 pb-0">
      <BaseRow v-if="!isLoading && chapter">
        <BaseCol cols="12">
          <BaseHeadline size="1" fake="3">
            {{ chapter.displayName }}
          </BaseHeadline>
        </BaseCol>

        <BaseCol cols="12">
          <BaseParagraph>
            {{ chapter.description }}
          </BaseParagraph>
        </BaseCol>

        <BaseCol cols="12">
          <BaseRow class="flex align-items-center">
            <BaseCol cols="12" md="6">
              <ChapterItemsCompletedLabel :chapter="chapter" />
            </BaseCol>

            <BaseCol cols="12" md="6" class="md:mt-0 mt-3 flex lg:justify-content-end">
              <BaseButton
                v-if="unusedItemId"
                :label="chapterStartLabel"
                @click="pushToUnusedItem"
              />
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>

      <BaseRow v-else>
        <BaseCol cols="12" class="text-center">
          <BaseProgressSpinner />
        </BaseCol>
      </BaseRow>
    </BaseCard>
  </BaseContainer>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import useApi from "@use/useApi";
import { computed, ref, watch } from "vue";
import ChapterItemsCompletedLabel from "@/modules/user-course-uc/components/ChapterItemsCompletedLabel";

export default {
  components: { ChapterItemsCompletedLabel },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const chapter = ref();
    const unusedItemId = ref(null);

    const courseChapterId = computed(() => {
      return route.params.courseChapterId;
    });

    const setUnusedItem = () => {
      const firstUnOpenedItem = Object.values(chapter.value.courseChapterItems).find((item) => {
        return !item.courseChapterItemProgression.lastOpenedAt;
      });

      unusedItemId.value = firstUnOpenedItem ? firstUnOpenedItem.id : null;
    };

    const { isLoading, docs, callApi } = useApi({
      module: "uc",
      method: "showCourseChapter",
      onMount: !!courseChapterId.value,
      params: {
        courseChapterId: courseChapterId.value,
      },
      onSuccess: () => {
        chapter.value = docs.value.data;
        setUnusedItem();
      },
    });

    const chapterStartLabel = computed(() => {
      return chapter.value.completedCourseChapterItemsCount
        ? "uc.my.chapters.show.continueChapter"
        : "uc.my.chapters.show.startChapter";
    });

    watch(courseChapterId, () => {
      if (courseChapterId.value) {
        callApi({
          courseChapterId: courseChapterId.value,
        });
      }
    });

    const pushToUnusedItem = async () => {
      try {
        await router.push({
          name: "MyCoursesShowChapterItem",
          params: {
            courseId: route.params.courseId,
            courseChapterId: route.params.courseChapterId,
            courseChapterItemId: unusedItemId.value,
          },
        });
      } catch {
        return;
      }
    };

    return {
      isLoading,
      chapter,
      unusedItemId,
      pushToUnusedItem,
      chapterStartLabel,
    };
  },
};
</script>
